import React from "react"
import Radio from "../../../shared/forms/Radio"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import * as actions from "actions"
import { URLS } from "urls"
import * as queries from "queries"
import { graphql } from "react-apollo"

const NextButton = ({ useProPhotographer, showModal, nextUrl }) => {
  return useProPhotographer ? (
    <button className="button u-center-text" onClick={showModal}>
      Næste
    </button>
  ) : (
    <Link className="button" to={nextUrl}>
      Næste
    </Link>
  )
}

//TODO Create Modal
const modalLink = {
  label: "læs mere her",
  modalContent: {
    title: "Fotopakke Pro",
    body: `
<p>Boligannoncer med professionelle billeder får langt flere seriøse henvendelser og mindsker antallet af forgæves henvendelser og fremvisninger.
Fotopakke Pro indeholder;</p>
<ul>
    <li>20 inde/ude billeder</li>
    <li>Redigering af billeder</li>
    <li>Blå himmel garanti</li>
    <li>Plantegning(er)</li>
    <li>Kørsel for fotografen</li>
    <li>Det er fotofirmaet E-Soft (Danmarks største) der er vores leverandør</li>
<li>Du ejer billederne når de er betalt og de kan fremsendes til dig via link</li>
</ul>
<small>
Fotopakke Pro er ikke omfattet af solgt eller gratis.<br> 
Vi fremsender faktura på dit køb indenfor 1-2 uger.
</small>
`,
  },
}

const SelectPhotoPackage = ({ dispatch, data }) => {
  if (data.loading) return <div className="section--loading"></div>
  const onToggleModal = () =>
    dispatch(actions.setVisibleModal("PHOTO_PACKAGE_INFO_MODAL", { nextUrl: URLS.SWAP.CREATE.S7_WHISHES }))
  const swapCase = data.ubUser.swapCase
  const setExternalPhotographer = (value) => () => {
    dispatch(actions.modifySwapCase({ externalPhotographer: value }))
  }
  const nextUrl = swapCase.externalPhotographer ? URLS.SWAP.CREATE.S7_WHISHES : URLS.SWAP.CREATE.S6_PHOTO_UPLOAD
  return (
    <section className="section u-full-height u-lightgrey flex-center">
      <div className="container">
        <h1 className="u-green-text">Nu skal der billeder på!</h1>
        <h4 className="u-darkgrey-text ">
          Upload 8-12 gode fotos eller tilkøb en Fotopakke Pro
          <br />
        </h4>

        <form onSubmit={(e) => e.preventDefault()}>
          <Radio
            label="Fotopakke Pro"
            description="1995,- inkl. moms"
            type="radio"
            name="externalPhotographer"
            value={swapCase.externalPhotographer}
            onChange={setExternalPhotographer(true)}
            modalLink={modalLink}
          />

          <Radio
            label="Nej tak"
            description="Jeg tager selv billeder og uploader dem"
            type="radio"
            name="externalPhotographer"
            value={!swapCase.externalPhotographer}
            onChange={setExternalPhotographer(false)}
          />

          <NextButton useProPhotographer={swapCase.externalPhotographer} showModal={onToggleModal} nextUrl={nextUrl} />
        </form>
      </div>
    </section>
  )
}

SelectPhotoPackage.displayName = "SelectPhotoPackage"
SelectPhotoPackage.propTypes = {}
SelectPhotoPackage.defaultProps = {}

const mapStateToProps = (state) => {
  return state.sale
}

const SelectPhotoPackageWithData = graphql(queries.UB_USER_QUERY)(SelectPhotoPackage)

export default connect(mapStateToProps)(SelectPhotoPackageWithData)
