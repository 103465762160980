import React from "react"
import { withRouter } from "react-router-dom"
import * as queries from "queries"
import { graphql, compose } from "react-apollo"
import * as actions from "actions"
import { connect } from "react-redux"

const Products = ({ fullService, externalPhotographer }) => {
  const title =
    fullService || externalPhotographer ? "Du har tilkøbt følgende:" : "Du har ikke tilkøbt nogle ekstra services"
  return (
    <div>
      <h4 className="u-darkgrey-text">{title}</h4>
      {fullService ? (
        <div>
          <h3 className="u-lightgreen-text">Hotline Pro</h3>
          <strong>3.995,- inkl. moms</strong>
        </div>
      ) : null}
      {externalPhotographer ? (
        <div>
          <h3 className="u-lightgreen-text">Fotopakke Pro</h3>
          <strong>1.995,- inkl. moms</strong>
        </div>
      ) : null}
    </div>
  )
}

const CheckBox = ({ name, value, onChange, onShowModal, className = false }) => {
  return (
    <div className={className ? "input-field__checkbox " + className : "input-field__checkbox"} onClick={onChange}>
      <input id={name} className="check-list" name={name} type="checkbox" checked={value}></input>
      <label>
        <span>
          Jeg accepterer <a onClick={onShowModal}> vilkårene </a> for selvsalg
        </span>
        <svg className="icon icon--black icon--check">
          <use xlinkHref="#icon-check"></use>
        </svg>
      </label>
    </div>
  )
}

const Finish = ({ userData, textData, dispatch }) => {
  if (userData.loading || textData.loading) return <div className="section--loading"></div>
  const data = userData
  if (data.ubUser === null) return <span>Du skal være logget indd</span>
  const selfSaleCase = data.ubUser.selfSaleCase
  const { fullService, externalPhotographer } = selfSaleCase
  const invoicingDetails =
    fullService || externalPhotographer ? "Vi fremsender faktura på dit køb indenfor 1-2 uger." : ""

  const onToggle = () => {
    data.updateQuery((prev) => ({
      ...prev,
      ubUser: {
        ...prev.ubUser,
        selfSaleCase: {
          ...prev.ubUser.selfSaleCase,
          wizardCompletedTermsAccepted: !prev.ubUser.selfSaleCase.wizardCompletedTermsAccepted,
        },
      },
    }))
  }
  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(actions.modifySelfSale({ wizardCompletedTermsAccepted: selfSaleCase.wizardCompletedTermsAccepted })).then(
      () => {
        dispatch(actions.setVisibleModal("SUCCESS_SELF_SALE_ADDED"))
      }
    )
  }

  const onShowModal = (e) => {
    e.stopPropagation()
    dispatch(actions.setHelpModalContent({ title: textData.siteText.headline, body: textData.siteText.text }))
  }

  return (
    <div className="selfsale__finish">
      <section className="u-lightgrey u-full-height flex-center">
        <div className="container">
          <div className="row">
            <div className="col-s-12 col-l-12">
              <div className="flex-column u-center-text">
                <form onSubmit={onSubmit} className="selfsale__upload-images__form">
                  <h1 className="u-green-text">Super, så er du færdig med din annonce!</h1>
                  <Products {...selfSaleCase} />
                  <CheckBox
                    value={selfSaleCase.wizardCompletedTermsAccepted}
                    onChange={onToggle}
                    className="flex-center"
                    onShowModal={onShowModal}
                  />
                  <div>
                    <small>{invoicingDetails}</small>
                  </div>
                  <button disabled={!selfSaleCase.wizardCompletedTermsAccepted} className="button">
                    Næste
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Finish.displayName = "Finish"
Finish.propTypes = {}
Finish.defaultProps = {}

const FinishWithData = compose(
  graphql(queries.UB_USER_QUERY, {
    name: "userData",
  }),
  graphql(queries.SITE_TEXT_QUERY, {
    name: "textData",
    options: {
      variables: {
        textId: "user_conditions_selfsale",
      },
    },
  })
)(Finish)

export default withRouter(connect()(FinishWithData))
